<template>
    <div class="record">
        <h5header :name="$t('asset.desc83')" :right="false" />
        <div class="record-con">
            <div class="record-price flex">
                <div class="price-left">
                    <div>+{{ info.amount }}</div>
                    <p>{{ info.status }}</p>
                </div>
                <img src="../../public/images/new/recordicon1.png" alt="" />
            </div>
            <div class="record-list">
                <div class="list-info flex">
                    <div>{{ $t('record.desc4') }}</div>
                    <p>{{ $t('asset.desc2') }}</p>
                </div>
                <div class="list-info flex">
                    <div>{{ $t('record.desc9') }}</div>
                    <p class="flex">{{dealAddress(info.toAddress) }} <img src="../../public/images/new/copy.png" alt="" @click="copy($event,info.toAddress)" /></p>
                </div>
                <div class="list-info flex">
                    <div>{{ $t('record.desc6') }}</div>
                    <p>{{ info.coinName }}</p>
                </div>
                <div class="list-info flex">
                    <div>{{ $t('record.desc10') }}</div>
                    <p>+{{ info.amount }} {{ info.coinName }}<br />
                       <span v-if="info.payBackAmount != null"> {{ $t('asset.desc159') }}{{info.payBackAmount}} {{ info.coinName }}</span>
                    </p>
                </div>
                <div class="list-info flex">
                    <div>{{ $t('record.desc20') }}</div>
                    <p>{{ info.tokenType }}</p>
                </div>
                <div class="list-info flex">
                    <div>{{ $t('asset.desc160') }}</div>
                    <p class="flex">{{dealAddress(info.txHash) }} <img src="../../public/images/new/copy.png" alt="" @click="copy($event,info.txHash)" /></p>
                </div>
                <div class="list-info flex">
                    <div>{{ $t('record.desc16') }}</div>
                    <p>{{ info.sn }}</p>
                </div>
                <div class="list-info flex">
                    <div>{{ $t('record.desc5') }}</div>
                    <p>{{ info.date }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import h5header from '@/componets/h5header.vue';
import Clipboard from '@/utils/libs/clipboard'
export default {
    components: {
        h5header
    },
    data() {
        return {
            info: {}
        }
    },
    mounted() {
        this.info = JSON.parse(localStorage.getItem('recordinfo'))
    },
    methods:{
        copy(e, text) {
            // @click="copy($event,googleRandom.secretKey)"
            let that = this;
            Clipboard(e, text).then((res) => {
                that.$message.success(this.$t('planet.desc61'))
            });
        },
        dealAddress(str) {
            if (str && str.length > 18) {
                return str.substring(0, 6) + '....' + str.substring(str.length - 6, str.length)
            } else {
                return str
            }
        },
    }
}
</script>

<style lang="less" scoped>
.record {
    padding: 0 16px;
    min-height: 100vh;
    background: url('../../public/images/new/h5bg.png') center no-repeat;
    background-size: 100% 100%;

    .record-con {
        padding: 56px 0;

        .record-price {
            padding: 24px;
            border-radius: 8px;
            background: #181818;

            .price-left {
                flex: 1;
                font-size: 12px;
                color: rgba(255, 255, 255, .6);
                line-height: 12px;

                div {
                    margin-bottom: 8px;
                    font-size: 24px;
                    color: #FFFFFF;
                    line-height: 24px;
                }
            }
            img{
                flex: 0 0 56px;
                width: 56px;
                height: 56px;
                margin-left: 12px;
            }
        }
        .record-list{
            padding: 0 16px;
            margin-top: 12px;
            border-radius: 8px;
            background: #181818;
            .list-info{
                padding: 16px 0;
                border-bottom: 1px solid rgba(255, 255, 255, 0.06);
                &:last-child{
                    border-bottom: 0;
                }
                div{
                    flex: 0 0 20%;
                    font-size: 13px;
                    color: rgba(255, 255, 255, .6);
                }
                p{
                    flex: 0 0 80%;
                    text-align: right;
                    font-size: 13px;
                    color: #fff;
                    justify-content: flex-end;
                    img{
                        width: 12px;
                        height: 12px;
                        margin-top: 3px;
                        margin-left: 8px;
                    }
                    span{
                        color: #C9FA5B;
                    }
                }
            }
        }
    }
}</style>